
$animationDuration: 0.22s;


@mixin defaultInfoBarSports {
  border-radius: 30px;
  border: 1px solid #000;
  background: #0E0C10;
  margin-bottom: 8px;
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  span {
    color: #8F84A4;
    font-size: 14px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.1px;
  }

  .money {
    color: #EEB665 !important;
    text-transform: uppercase;
  }
}

@mixin infoBarSports {
  border-radius: 30px;
  border: 1px solid #000;
  background: #0E0C10;
  margin-bottom: 8px;
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  span {
    color: #8F84A4;
    font-size: 14px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.1px;
  }

  .money {
    color: #EEB665 !important;
    text-transform: uppercase;
  }

}

@mixin regularGridResponsive($width, $gap) {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax($width, 1fr));
  gap: $gap;
}

@mixin smallCountdown {
  background: $danger;
  padding: 0 16px;
  gap: 8px;

  div {
    border: none !important;
    font-size: 12px !important;
    width: fit-content;
    height: fit-content;
    min-width: unset;
    min-height: unset;
    padding: 0;
    gap: 0;

    span {
      display: inline;
    }
  }

  span {
    display: none;
  }
}

@mixin spinningValue {
  display: flex;
  flex-direction: row;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 100%;
  text-align: start;

  .value {
    overflow: hidden;

    //height: 25px;
    .slide {
      display: flex;
      flex-direction: column;

      &.sliding {
        animation-name: slideAnim;
        animation-duration: $animationDuration;
        animation-timing-function: ease-out;
        animation-iteration-count: infinite;
      }

      &.rolling {
        animation-name: rollAnim;
        animation-duration: $animationDuration;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
      }


      @keyframes rollAnim {
        from {
          transform: translateY(0);

        }

        to {
          transform: translateY(-50%);

        }
      }

      @keyframes slideAnim {
        0% {
          transform: translateY(0);
        }

        80% {
          transform: translateY(-28px);
        }

        to {
          transform: translateY(-30px);
        }
      }
    }
  }
}

@mixin checkmark {
  .checkmark_label {
    display: flex;
    position: relative;
    //margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    border-radius: 100px;
    width: 100%;
    gap: 8px;
    align-items: center;

    input {
      position: absolute;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .checkmark2 {
      height: 25px;
      width: 25px;
      border-radius: 50px;
      background-color: transparent;
      border: 2px solid $dark_text3;
      position: absolute;
      left: 16px;
    }

    .checkBoxBlock2 {
      color:  $dark_text3;
      font-size: 11px;
      border-radius: 100px;
      border: 1px solid transparent;
      padding: 16px;
      width: 100%;
      padding-left: 50px;

      .chekboxText {
        line-height: 1.5;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

  .checkmark_label input:checked ~ .checkmark2{
    background-color:  transparent !important;
    border: 2px solid $primary-color;

    .checkBoxBlock {
      color: white;
    }

    .checkBoxBlock2 {
      color: white;
    }

  }

  .checkmark_label input:checked ~ .checkBoxBlock {
    border: 1px solid #EEB665;
    & .chekboxBreak {
      color: white!important;
    }
  }

  .checkmark_label input:checked ~ .checkBoxBlock2 {
    border: 1px solid #EEB665;

    .chekboxBreak {
      color: var(--theme-color)
    }
  }

  .checkmark_label .checkmark:after {
    left: 9px;
    top: 4px;
    width: 5px;
    height: 10px;
    border: solid #1E1E1E;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    display: none;
  }

  .checkmark_label .checkmark2:after {
    left: 4px;
    top: 4px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: $primary-color;
    border: solid $primary-color;
    display: none;
  }

  .checkmark_label input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark_label input:checked ~ .checkmark2:after {
    display: block;
  }

  .checkmark_label input:checked {
    .pauseWrapper {
      border: none;
      background: grey;
    }
  }

  .checkmark:after {
    content: "";
    position: absolute;
  }

  .checkmark2:after {
    content: "";
    position: absolute;
  }
}

@mixin jpBadge {
  border-radius: 40px;
  padding: 10px;
  color: #FFF;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;

  &.cool_start {
    background: #097B9E;
    box-shadow: 0px -4px 5px 0px rgba(106, 2, 160, 0.40) inset, 0px 4px 5px 0px rgba(238, 182, 101, 0.40) inset;
  }

  &.warm_up {
    background: #ED9E15;
    box-shadow: 0px -4px 5px 0px rgba(106, 2, 160, 0.40) inset, 0px 4px 5px 0px rgba(238, 182, 101, 0.40) inset;
  }

  &.blazing_hot {
    background: #E9550C;
    box-shadow: 0px -4px 5px 0px rgba(106, 2, 160, 0.40) inset, 0px 4px 5px 0px rgba(238, 182, 101, 0.40) inset;
  }

  &.on_fire {
    background: #9D0000;
    box-shadow: 0px -4px 5px 0px rgba(106, 2, 160, 0.40) inset, 0px 4px 5px 0px rgba(238, 182, 101, 0.40) inset;
  }
}

@mixin font-32px {
  font-size: 32px;

  @media (max-width: 1024px) {
    font-size: 28px;
  }

  @media (max-width: 768px) {
    font-size: 26px;
  }

  @media (max-width: 480px) {
    font-size: 24px;
  }
}

@mixin font-24px {
  font-size: 24px;

  @media (max-width: 1024px) {
    font-size: 22px;
  }

  @media (max-width: 768px) {
    font-size: 20px;
  }

  @media (max-width: 480px) {
    font-size: 18px;
  }
}

@mixin font-20px {
  font-size: 20px;

  @media (max-width: 1024px) {
    font-size: 18px;
  }

  @media (max-width: 768px) {
    font-size: 17px;
  }

  @media (max-width: 480px) {
    font-size: 16px;
  }
}

@mixin font-16px {
  font-size: 16px;

  @media (max-width: 1024px) {
    font-size: 15px;
  }

  @media (max-width: 768px) {
    font-size: 14px;
  }

  @media (max-width: 480px) {
    font-size: 14px;
  }
}

@mixin font-12px {
  font-size: 12px;

  @media (max-width: 1024px) {
    font-size: 12px;
  }

  @media (max-width: 768px) {
    font-size: 11px;
  }

  @media (max-width: 480px) {
    font-size: 11px;
  }
}
